import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import Store from '../../../../store';
import MessageList from './MessageList';
import UserInput from './UserInput';
import Header from './Header';
import { getChatHistory, sendMsg, markChatAsRead } from './../../../../store/actions/messages'
import { S3ClientForUserChat } from "../../../../utils/s3Config";
import { toastr } from "react-redux-toastr";

function ChatWindow(props) {
  const dispatch = useDispatch();

  const { userAuth } = Store.getState();

  const [messageList, setMessageList] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [fileLoader, setFileLoader] = useState(false);
  const [freshChat, setFreshChat] = useState(false);
  const [isUp, setIsUp] = useState(true);
  
  const { activeChats } = useSelector((state) => ({
		activeChats: state.messagesReducer.activeChats,
  }), shallowEqual);
	
	useEffect(() => {
    let chatIndex = activeChats.findIndex(ac => ac.id == props?.friendProfile?.id);
    if(chatIndex != -1) {
      let currentChatHistory = activeChats[chatIndex].messages;
      setMessageList(currentChatHistory);
    }
	}, [activeChats]);
  
  useEffect(() => {
    const data = {second_user_id: props?.friendProfile?.id};
		// dispatch(getChatHistory(data));
    dispatch(markChatAsRead(data));

    //////////////////////////////////////////////////
    /////////////       To do       //////////////////
    //// Please uncommit bellow code to enable      //
    ////          chat functionality                //
    //////////////////////////////////////////////////

    //check is freshchat widget opened
    // window.fcWidget.on("widget:opened", function(resp) {
    //   setFreshChat(true)
    //   //Function to do some task
    // });

    //check is fresh chat widget closed

    // window.fcWidget.on("widget:closed", function(resp) {
    //   setFreshChat(false)
    //   //Function to do some task
    // });
    



	}, []);

	const _onMessageWasSent = (message) => {
    dispatch(sendMsg({chatId: props?.friendProfile?.id, message: message}));
	}
  
  const onFilesSelected = useCallback(acceptedFiles => {
    setFileLoader(true);
    let file = acceptedFiles[0];

    if (file.length === 0) {
      toastr.info('Error', 'Please upload correct file');
      return;
    }
    if (file.size > 5000000) {
      toastr.info('Error', 'Please upload file less than 5MB');
      return;
    }

    S3ClientForUserChat
      .uploadFile(file, new Date().getTime())
    .then(data => {
      if(data?.location) {
        dispatch(sendMsg({
          chatId: props?.friendProfile?.id,
          message: {
            type: 'file',
            first_user_id: userAuth.userId,
            second_user_id: props.friendProfile?.id,
            message: data?.location,
            fileName: acceptedFiles[0].name,
            created_at: new Date()
          }
        }));
      }
      setFileLoader(false);
    })
    .catch(err => {
      toastr.error('Error', 'Image Uploading Fail');
      setFileLoader(false);
    })
  }, [])

	const handleClick = () => {
		setIsOpen(!isOpen)
	}

  let classList = [
    'sc-chat-window',
    (isOpen ? 'opened' : 'closed')
  ];

  const handleIsUpChat = () => {
    
    setIsUp(!isUp)
	}

  let isUpChat = [
    'sc-chat-window',
    (isUp ? 'up' : 'down')
  ]
  let pixels= isUp ? '0px' : '-340px'

  return (
    <div className={freshChat ? 'freshchat-widget-opened' : 'freshchat-widget-closed'}>
    {/* i dont need inline styling here so i commented line below
      <div className={classList.join(' ')} style={{right: (props.chatIndex*340+25)+'px'}}> 
    */}
    <div className={classList.join(' ')} style={{bottom:pixels}} > 
      
        <Header
          friendProfile={props.friendProfile}
          onClose={handleClick}
          handleIsUpChat={handleIsUpChat}
        />
        <MessageList
          messages={messageList}
          imageUrl={props.friendProfile.imageUrl}
          userAuth={userAuth}
          fileLoader={fileLoader}
          friendProfile={props.friendProfile}
        />
        <UserInput
          onSubmit={_onMessageWasSent}
          onFilesSelected={onFilesSelected}
          showEmoji={true}
          friendProfile={props.friendProfile}
        />
       
      </div>
    </div>
  );
}

export default ChatWindow;
