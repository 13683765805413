import { fetchClasses } from '../../api/class';
import { getLWUserCourses } from '../../api/learn_worlds';

import { toastr } from "react-redux-toastr";

export function getClassesStart() {
    return {
        type: 'GET_CLASS_START'
    }
};

export function getClassesSuccess(payload) {
    return {
        type: 'GET_CLASS_SUCCESS',
        payload,
    }
};

export function getClassesFailure(payload) {
    return {
        type: 'GET_CLASS_FAILURE',
        payload,
    }
};

// This method fetches all the courses information saved in our database...
export const getClasses = () => async dispatch => {
    dispatch(getClassesStart());
    try {
        const response = await fetchClasses();
        if (Object.keys(response).length) {
            dispatch(getClassesSuccess(response));
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong");
        dispatch(getClassesFailure(e));
    }
};

// This method fetches all the courses information saved in Learn World...
export const getLWClasses = () => async dispatch => {
    dispatch(getClassesStart());
    try {
        const response = await getLWUserCourses();
        if (Object.keys(response).length) {
            dispatch(getClassesSuccess(response));
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong");
        dispatch(getClassesFailure(e));
    }
};
