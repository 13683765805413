export const HANDLE_LOGGED_OUT = 'HANDLE_LOGGED_OUT';
export const HANDLE_LOGGED_IN = 'HANDLE_LOGGED_IN';
export const HANDLE_FORM_STEP = 'HANDLE_FORM_STEP';
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_STATE = 'GET_STATE';
export const SAVE_STEP_SUCCESS = 'SAVE_STEP_SUCCESS';
export const SAVE_APP_SUCCESS = 'SAVE_APP_SUCCESS';
export const HANDLE_FORM_LOADER = 'HANDLE_FORM_LOADER';
export const HANDLE_SAVE_FORM_LOADER = 'HANDLE_SAVE_FORM_LOADER';
export const STORE_SAVED_DATA = 'STORE_SAVED_DATA';
export const SET_SAVED_DATA = 'SET_SAVED_DATA';
export const HANDLE_REQ_LOADER = 'HANDLE_REQ_LOADER';
export const APP_SUBMITTED = 'APP_SUBMITTED';
export const SAVE_STEP_SUCCESS_FOR_LOGGED_IN = 'SAVE_STEP_SUCCESS_FOR_LOGGED_IN';
export const SAVE_STEP_SUCCESS_FOR_LOGGED_OUT = 'SAVE_STEP_SUCCESS_FOR_LOGGED_OUT';
export const SAVE_APP_SUCCESS_FOR_LOGGED_IN = 'SAVE_APP_SUCCESS_FOR_LOGGED_IN';
export const SAVE_APP_SUCCESS_FOR_LOGGED_OUT = 'SAVE_APP_SUCCESS_FOR_LOGGED_OUT';
export const HANDLE_PROGRAM_SELECTION = 'HANDLE_PROGRAM_SELECTION';