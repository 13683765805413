import { declineRequest } from "../actions/network";

const initialNetworkData = {
    allChatThreads: [],
    activeChats: [],
    loadingChatThreads: false,
    startIndex: 0,
    endIndex: 10,
    errors: null,
    unreadMsgCount: 0,
    userTypingThreads: [],
    onlineUser: [],
    registerNotification: false
};

const messagesReducer = (state = initialNetworkData, action) => {
    switch(action.type) {
        case "GET_ALL_CHAT_THREADS_SECCUSS":
            let allThreads = action.payload;
            const msgCount = allThreads?.filter(t => !!t.unread_messages_count)?.length || 0;
            return { ...state, allChatThreads: allThreads, unreadMsgCount: msgCount };
        case "MARKED_CHAT_AS_READ":
            let updatedCount = state.unreadMsgCount;
            let readThreads = [...state.allChatThreads];
            const updatedThreadUnread = readThreads.findIndex(ac => ac.id == action.payload?.second_user_id);
            if(updatedThreadUnread != -1) {
                readThreads[updatedThreadUnread].unread_messages_count = 0;
                updatedCount = readThreads.filter(t => !!t.unread_messages_count)?.length || 0;
            }
            return { ...state, unreadMsgCount: updatedCount, allChatThreads: readThreads };
        case "OPEN_CHAT_BOX":
            let activeChatsUpdated = [...state.activeChats];
            let chatBox = state.allChatThreads.filter(cb => cb.id == action.payload.id);
            const boxIndex = activeChatsUpdated.findIndex(ac => ac.id == action.payload.id);
            if(boxIndex == -1) {
                activeChatsUpdated.push(chatBox[0] || action.payload);
            }
            return { ...state, activeChats: activeChatsUpdated };
        case "CLOSE_CHAT_BOX":
            activeChatsUpdated = [...state.activeChats];
            activeChatsUpdated = activeChatsUpdated.filter(ac => ac.id != action.payload.chatId);
            return { ...state, activeChats: activeChatsUpdated };
        case "SEND_MSG_SUCCESS":
            let activeChatsList = [...state.activeChats];
            let allChatsList = [...state.allChatThreads];
            let updateCountOnNewMsg = state.unreadMsgCount;
            const activeChatIndex = activeChatsList.findIndex(ac => ac.id == action.payload?.chatId);
            const allChatsIndex = allChatsList.findIndex(ac => ac.id == action.payload?.chatId);
            // if(activeChatIndex != -1) {
            //     activeChatsList[activeChatIndex]?.messages?.unshift(action.payload.message);
            // }
            if(allChatsIndex != -1) {
                allChatsList[allChatsIndex]?.messages?.unshift(action.payload.message);
            }
            if(action.payload?.msgReceived && allChatsIndex != -1 && activeChatIndex == -1) {
                allChatsList[allChatsIndex].unread_messages_count += 1;
                updateCountOnNewMsg = allChatsList.filter(t => !!t.unread_messages_count)?.length || 0;
            }
            return { ...state, activeChats: activeChatsList, allChatThreads: allChatsList, unreadMsgCount: updateCountOnNewMsg };
        case "ADD_TYPING_THREAD":
            let typingThreads = [...state.userTypingThreads];
            if(!typingThreads.includes(action.payload)) {
                typingThreads.push(action.payload);
            }
            return { ...state, userTypingThreads: typingThreads };
        case "REMOVE_TYPING_THREAD":
            typingThreads = [...state.userTypingThreads];
            if(typingThreads.includes(action.payload)) {
                typingThreads.splice(typingThreads.indexOf(action.payload), 1);
            }
            return { ...state, userTypingThreads: typingThreads };
        case "SET_REG_NOTIF":
            return { ...state, registerNotification: action.payload };
        default:
            return {...state};
    }
}

export default messagesReducer;
