import React, { useRef, useEffect, useState } from 'react';

function PopupWindow(props) {
  const emojiPopup = useRef();
  let scLauncher = useRef();
  const { children, isOpen } = props;

  useEffect(() => {
    scLauncher = document.getElementById("sc-launcher");
    scLauncher?.addEventListener("click", interceptLauncherClick);
		return () => {
      scLauncher?.removeEventListener("click", interceptLauncherClick);
		};
  }, []);

  const interceptLauncherClick = (e) => {
    const clickedOutside = !emojiPopup.current.contains(e.target) && isOpen;
    clickedOutside && props.onClickedOutside(e);
  }

  return (
    <div className="sc-popup-window" ref={emojiPopup}>
      <div className={`sc-popup-window--cointainer ${isOpen ? '' : 'closed'}`}>
        <input
          onChange={props.onInputChange}
          className="sc-popup-window--search"
          placeholder="Search emoji..."
        />
        {children}
      </div>
    </div>
  );
}

export default PopupWindow;
