import { useDispatch } from 'react-redux';
import Link from 'next/link';
import { closeChatBox } from './../../../../store/actions/messages';

function Header(props) {
  const dispatch = useDispatch();
  const { friendProfile, handleIsUpChat } = props;
  return (
    <div className="sc-header">
      <div className="sc-header--back-button" onClick={() => {props.onClose; dispatch(closeChatBox({chatId: friendProfile?.id}))}}>
        <div></div>
      </div>
      {/* <Link href={`/profile/user/${btoa(friendProfile?.id)}`}>
        <div className="sc-header--img">
          <img className="" src={friendProfile?.imageUrl} alt="" />
        </div>
      </Link> */}
      <div></div>
        <div className="sc-header--team-name" onClick={()=>{handleIsUpChat()}} >
          <Link href={`/profile/user/${btoa(friendProfile?.id)}`}>
              {friendProfile.name}
          </Link>
        </div>
      <div className="sc-header--close-button" onClick={() => {props.onClose; dispatch(closeChatBox({chatId: friendProfile?.id}))}}>
        <img src="/chat/close-icon.png" alt="" />
      </div>
    </div>
  );
}

export default Header;
