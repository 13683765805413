// import "./style.scss";
import React, { useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
const Slider = dynamic(() => import('react-slick'), { loading: () => <></> });
import Link from 'next/link';
import Image from 'next/image';

function Faculty(props) {
	const { primaryColor, secondaryColor, gradient } = props.theme.bayanTheme;
	const [state, setState] = useState([]);
	const settings = {
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		infinite: true,
		dots: false,
		lazyLoad: true,
		draggable: true,
		swipe: true,
		touchThreshold: 100,
		swipeToSlide: true,
		autoplay: true,
	  autoplaySpeed: 3000,
		responsive: [
			{
			  breakpoint: 600,
			  settings: {
				slidesToShow: 2,
			  }
			},
			{
			  breakpoint: 480,
			  settings: {
				slidesToShow: 1,
			  }
			}
		  ]
	}

	useEffect(() => {
		const dataToBeFilter = ["Dr. Hadia Abdullah", "Salam Al-Marayati", "Edina Lekovic", "Brie Loskota"];
		
		const data = props.faculties.filter((val) => {
			if (!dataToBeFilter.includes(val.name)) {
				return val;
			}
		});
		setState(data);
	}, []);

	return (
		<>
			<div className="bayan-faculty">
				<div className="container">
					<h1 className="text-center headingF">{props?.wc_faculty?.heading}</h1>
					<h2 className="text-center sub-headingF">{props?.wc_faculty?.sub_heading} </h2>
					<div className="fac-logos">
						{
							props?.wc_faculty?.wc_logo_img.map((val,i) => {
								return <span key={i}>
							<Image
								src={`${process.env.CMS_API_ORIGIN}${val?.img?.url}`}
								alt="University of michigan"
								width="120"
								height="70"
								loading="lazy"
								className="mob-img"
							/>
						</span>
							})
						}

					</div>
					
					<div className="inner-faculty">
						
							<Slider {...settings}>
								{
								state.length ? state.map((faculty) => {
										return <div key={faculty.name}>
												{/* <img src={faculty.image_url} alt={faculty.name} /> */}
											<Image
												src={faculty.image_url}
												alt={faculty.name}
												width={263}
												height={245}
												loading="lazy"
												className="mob-img"
											/>
												<div className="name">{faculty.name}</div>
											</div>
									}) : <></>
								}
							</Slider>
					
					</div>
				</div>
				<div className="class-text text-center see-all faculty-btn">
					{/* <div className="mobile-only see-all"> */}
					<Link href="/faculty" as="/faculty">
						<a>See All Faculty</a>
					</Link>
				</div>
				
			</div>
		</>
	);
}

export default Faculty;
