import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from "react-redux";
import { userStartedTyping, userStoppedTyping } from '../../../../store/actions/messages'
import Store from '../../../../store';
import SendIcon from './icons/SendIcon';
import FileIcon from './icons/FileIcon';
import EmojiIcon from './icons/EmojiIcon';
import PopupWindow from './popups/PopupWindow';
import EmojiPicker from './emoji-picker/EmojiPicker';


function UserInput(props) {
  const { userAuth } = Store.getState();
  const dispatch = useDispatch();
  let fileUploadButton = useRef(), userInput = useRef();
	const [inputActive, setInputActive] = useState(false);
	const [inputHasText, setInputHasText] = useState(false);
	const [emojiPickerIsOpen, setEmojiPickerIsOpen] = useState(false);
	const [emojiFilter, setEmojiFilter] = useState('');
	const [emojiPickerButton, setEmojiPickerButton] = useState('');
  let timeout = null;
  let userIsTyping = false;

  useEffect(() => {
    setEmojiPickerButton(document.getElementById(props.friendProfile?.id+'-sc-emoji-picker-button'));
  }, []);

  const data = {
    thread_room: 'private:room:'+props.friendProfile?.id,
    thread_id: userAuth.userId
  };

  const timeoutFunction = () => {
    userIsTyping = false;
    dispatch(userStoppedTyping(data));
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      return _submitText(event);
    }
    if(!userIsTyping && event.target.innerHTML.length !== 0) {
      userIsTyping = true;
      dispatch(userStartedTyping(data));
      timeout = setTimeout(timeoutFunction, 2000);
    } else {
      clearTimeout(timeout);
      timeout = setTimeout(timeoutFunction, 2000);
    }
  }

  const handleKeyUp = (event) => {
    const inputHasText = event.target.innerHTML.length !== 0 &&
      event.target.innerText !== '\n';
    setInputHasText(inputHasText);
  }

  const _showFilePicker = () => {
    fileUploadButton.click();
  }

  const toggleEmojiPicker = (e) => {
    e.preventDefault();
    setEmojiPickerIsOpen(!emojiPickerIsOpen);
  }

  const closeEmojiPicker = (e) => {
    if (emojiPickerButton.contains(e.target)) {
      e.stopPropagation();
      e.preventDefault();
    }
    setEmojiPickerIsOpen(false);
  }

  const _submitText = (event) => {
    event.preventDefault();
    const text = userInput.textContent;
    if (text && text.length > 0) {
      props.onSubmit({
        first_user_id: userAuth.userId,
        second_user_id: props.friendProfile.id,
        type: 'text',
        message: text,
        created_at: new Date()
      });
      userInput.innerHTML = '';
    }
  }

  const _onFilesSelected = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      props.onFilesSelected(event.target.files);
    }
  }

  const _handleEmojiPicked = (emoji) => {
    setEmojiPickerIsOpen(false);
    if(inputHasText) {
      userInput.innerHTML += emoji;
    } else {
      props.onSubmit({
        first_user_id: userAuth.userId,
        second_user_id: props.friendProfile.id,
        type: 'emoji',
        message: emoji,
        created_at: new Date()
      });
    }
  }

  const handleEmojiFilterChange = (event) => {
    const emojiFilter = event.target.value;
    setEmojiFilter(emojiFilter);
  }

  const _renderEmojiPopup = () => (
    <PopupWindow
      isOpen={emojiPickerIsOpen}
      onClickedOutside={closeEmojiPicker}
      onInputChange={handleEmojiFilterChange}
    >
      <EmojiPicker
        onEmojiPicked={_handleEmojiPicked}
        filter={emojiFilter}
      />
    </PopupWindow>
  )

  const _renderSendOrFileIcon = () => {
    if (inputHasText) {
      return (
        <div className="sc-user-input--button">
          <SendIcon onClick={_submitText} />
        </div>
      );
    }
    return (
      <div className="sc-user-input--button">
        <FileIcon onClick={_showFilePicker} />
        <input
          type="file"
          name="files[]"
          multiple
          ref={(e) => { fileUploadButton = e; }}
          onChange={_onFilesSelected}
        />
      </div>
    );
  }

  return (
    <form className={`sc-user-input ${(inputActive ? 'active' : '')}`}>
      <div
        role="button"
        tabIndex="0"
        onFocus={() => { setInputActive(true); }}
        onBlur={() => { setInputActive(false); }}
        ref={(e) => { userInput = e; }}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        contentEditable="true"
        placeholder="Type a message"
        className="sc-user-input--text"
      >
      </div>
      <div className="sc-user-input--buttons">
        <div className="sc-user-input--button"></div>
        <div className="sc-user-input--button">
          {props.showEmoji && <EmojiIcon
            onClick={toggleEmojiPicker}
            isActive={emojiPickerIsOpen}
            tooltip={_renderEmojiPopup()}
            id={props?.friendProfile?.id}
          />}
        </div>
        {_renderSendOrFileIcon()}
      </div>
    </form>
  );
}

export default UserInput;
