// import "./style.scss";
import Image from "next/image";
import Slider from "react-slick";
import dynamic from "next/dynamic";

const ReactAudioPlayer = dynamic(() => import("react-audio-player"), {
  loading: () => <></>,
  ssr: false,
});

// import ReactAudioPlayer from "react-audio-player";

function BayanNews(props) {
  const news_events =
    props.news_events && props.news_events.length ? props.news_events : null;
  const page_title =
    props.news_events && props.news_events.length ? props?.page_title : null;
  // console.log(news_events && news_events[0]?.background_image, 'bbbII');
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    dots: true,
    draggable: true,
    swipe: true,
    touchThreshold: 100,
    swipeToSlide: true,
    lazyLoad: false,
    // autoplay: true,
    // autoplaySpeed: 3000
  };

  return (
    <>
      <div className="bayan-media bayan-media-container bayan-media-blue mobile-event">
        <div className="media-container">
          <h1 className="text-center headingF">
            {page_title ? page_title : "News & Events"}
          </h1>
          <div className="media-slider">
            {news_events?.length && (
              <Slider {...settings}>
                {news_events.map((event) => (
                  <div>
                    <div>
                      <div className="inner-media blue-text">
                        <span>
                          {event?.Type === "Video" ? (
                            <>
                              {event?.Video_url ? (
                                <iframe
                                  width="100%"
                                  height="100%"
                                  src={event.Video_url}
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              ) : (
                                <a href={event?.Video_url} target="_blank">
                                  <Image
                                    src={`${process.env.CMS_API_ORIGIN}${
                                      event?.Image?.url ? event?.Image?.url : ""
                                    }`}
                                    alt="master-degree-prograe"
                                    width={585}
                                    height={382}
                                    loading="lazy"
                                    className="mob-img"
                                  />
                                </a>
                              )}
                            </>
                          ) : (
                            <Image
                              src={`${process.env.CMS_API_ORIGIN}${
                                event?.Image?.url ? event?.Image?.url : ""
                              }`}
                              alt="master-degree-prograe"
                              width={585}
                              height={382}
                              loading="lazy"
                              className="mob-img"
                            />
                          )}
                          {event?.Type == "Audio" && (
                            <div className="podcast-image">
                              <Image
                                src={`${process.env.CMS_API_ORIGIN}${
                                  event?.Audio_img?.url
                                    ? event?.Audio_img?.url
                                    : ""
                                }`}
                                alt="master-degree-prograe"
                                width={185}
                                height={185}
                                loading="lazy"
                                className="mob-img"
                              />
                              <ReactAudioPlayer
                                src={event?.Audio_url ? event?.Audio_url : ""}
                                // autoPlay
                                controls
                              />
                            </div>
                          )}
                        </span>

                        <div className="right-detail blue-text">
                          <h3>{event?.Title ? event?.Title : ""} </h3>
                          <p>{event?.Subtitle ? event?.Subtitle : ""} </p>

                          <div className="button-container">
                            {event?.Type !== "Video" ? (
                              <div className="left">
                                <a
                                  target="_blank"
                                  href={
                                    event?.Button_link ? event?.Button_link : ""
                                  }
                                  className="bayan-button"
                                >
                                  {event?.Button_text ? event?.Button_text : ""}
                                </a>
                              </div>
                            ) : (
                              <>
                                <div className="">
                                  <a
                                    target="_blank"
                                    href="https://direct.bayanclaremont.org/course/series"
                                    className="bayan-button"
                                  >
                                    {event?.Button_text
                                      ? event?.Button_text
                                      : ""}
                                  </a>
                                </div>
                                <div className="right">
                                  <p>{event?.Caption ? event?.Caption : ""}</p>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default BayanNews;
