import { generateUniqueId } from './../../utils';
const initialPostsData = {
    posts: [],
    loadingPosts: false,
    hasMoreRecords: true,

    offset: 0,
    fromOffsetToGetNextDataFeedCount: 5,
    errors: null,
};

const postReducer = (state = initialPostsData, action) => {
    switch (action.type) {
        case "GET_POST_SUCCESS":
            if (typeof action.payload.data == "undefined" || action.payload?.data?.length == 0) {
                state.hasMoreRecords = false;
            } else {
                state.offset += state.fromOffsetToGetNextDataFeedCount;
                const ids = new Set(state.posts.map(d => d.post.id));
                var merged = [...state.posts, ...action.payload.data.filter(d => !ids.has(d.post.id))];
                state.posts = merged;
            }
            
            return { ...state };
        case "GET_TAGGED_POST_SUCCESS":
            if (typeof action.payload.data == "undefined") {
                state.hasMoreRecords = false;
            } else {
                state.offset += 0;
                const ids = new Set(state.posts.map(d => d.post.id));
                var merged = [...state.posts, ...action.payload.data.filter(d => !ids.has(d.post.id))];
                state.posts = merged;
            }

            return { ...state };

        // case "ADD_POST_SUCCESS":
        // case "SHARE_POST_SUCCESS":
        // case "DELETE_POST_SUCCESS":
        case "RESET_EXISTING_POSTS":
            // will fetch the latest posts again by infinite scroll on /home/index component...
            // As we making all post empty so on scroll all latest post come again...
            state.offset = 0; 
            return { ...state, posts: [], hasMoreRecords: true, offset: 0};

        // case "LIKE_POST_START":
        //     return { ...state, loadingPosts: true};
        case "LIKE_POST_SUCCESS":
            let newData = state.posts.map(post => {
                if (post?.post.id === action.payload.post_id) {
                    if (action.payload.is_liked) {
                        post.post.likes_count++;
                        if(action.payload.liked_by_me)
                            post.is_liked_by_me = 1;
                    } else {
                        if(action.payload.liked_by_me)
                            post.is_liked_by_me = 0;
                        post.post.likes_count--;
                    }
                }
                return post;
            });
            return { ...state, posts: newData};
        case "LIKE_POST_FAILURE":
            return {...state, errors: action.errors};


        case "ADD_COMMENT_SUCCESS":
            // Extracting user first and last name...start....
            let commentUser = action.payload.user;
            let commentData = action.payload.comment;
            let commentPostId = action.payload.post_id;
            // Extracting user first and last name... end....
            // Generating new comment... start....
            let newComment = {
                id: commentData?.id,
                comment_text: commentData?.comment_text,
                commentable_id: commentData?.commentable_id,
                created_at: new Date(),
                likes_count: 0,
                likes: [],
                user: {
                    id: commentUser?.id,
                    first_name: commentUser?.first_name,
                    last_name: commentUser?.last_name,
                    image_url: commentUser?.image_url,
                }

            }

            // Generating new comment... end....
            // Appending new comment in post coomment array... start....
            let updatedPosts = state.posts.map(post => {
                if (post?.post.id === commentPostId) {
                    post?.post?.comments.push(newComment);
                    post.post.comments_count++;
                }
                return post;
            });
            // Appending new comment in post coomment array... end....
            return { ...state, posts: updatedPosts };

        case "LIKE_COMMENT_SUCCESS":
            // Incrementing and decrending post comment likes... start....
            let updatedPostComments = state.posts.map(post => {
                if (post?.post.id === action.payload.post?.id) {
                    post?.post?.comments?.forEach(comment => {
                        if (comment.id === action.payload.comment.id) {
                            if (action.payload.is_liked) {
                                comment?.likes?.push({ user_id: action.payload.user_id, likeable_id: action.payload.comment.id});
                                comment.likes_count++;
                            } else  {
                                comment.likes = comment?.likes?.filter(val => val.user_id !== action.payload.user_id && val.likeable_id === action.payload.comment.id);
                                comment.likes_count--;
                            }
                        }
                    });
                }
                return post;
            });
            // Incrementing and decrending post comment likes... end....
            return { ...state, posts: updatedPostComments };
        case "ADD_POST_SOCKET":
            const ids = new Set(state.posts.map(d => d.post.id));
            const addSocketPost = [...state.posts];
            if(action.payload.isUpdated) {
                
                let updatedPostIndex = addSocketPost.findIndex(sp => sp?.post?.id == action.payload?.post?.id);
                if(updatedPostIndex != -1) {
                    addSocketPost[updatedPostIndex] = action.payload;
                } else {
                    // Updating private post and making it public, other users does not have this post that's why adding this one.
                    addSocketPost.unshift(action.payload);
                }
            } else if(!ids.has(action.payload.post.id)) {
                addSocketPost.unshift(action.payload);
            }            
            return { ...state, posts: addSocketPost };
        case "DELETE_POST_SOCKET":
            const deleteSocketPost = state.posts?.filter(p => action.payload.post_id != p?.post.id);

            return { ...state, posts: deleteSocketPost };
        // case "SHARE_POST_SOCKET":
        //     const addSharedSocketPost = [...state.posts];
        //     return { ...state, posts: addSharedSocketPost };
        case "ADD_SINGLE_POST":
            return { ...state, posts: [action.payload]}
        default:
            return {...state};
    }
}

export default postReducer;
