import { declineRequest } from "../actions/network";

const initialNetworkData = {
    connections: [],
    loadingConnections: false,
    suggestions: [],
    loadingSuggestions: false,
    requests: [],
    loadingRequests: false,
    startIndex: 0,
    endIndex: 10,
    errors: null,
    unreadNotifCount: 0,
    notifications: [],
};

const networkReducer = (state = initialNetworkData, action) => {
    switch(action.type) {
        case "GET_CONNECTIONS_START":
            return { ...state, loadingConnections: true };
        case "GET_CONNECTIONS_SUCCESS":
            return { ...state, connections: action.payload.data, loadingConnections: false };
        case "GET_CONNECTIONS_FAILURE":
            return { ...state, errors: action.errors, loadingConnections: false };
        case "GET_SUGGESTIONS_START":
            return { ...state, loadingSuggestions: true };
        case "GET_SUGGESTIONS_SUCCESS":
            return { ...state, suggestions: action.payload.data, loadingSuggestions: false };
        case "GET_SUGGESTIONS_FAILURE":
            return { ...state, errors: action.errors, loadingSuggestions: false };
        case "GET_REQUESTS_START":
            return { ...state, loadingRequests: true };
        case "GET_REQUESTS_SUCCESS":
            return { ...state, requests: action.payload.data, loadingRequests: false };
        case "GET_REQUESTS_FAILURE":
            return { ...state, errors: action.errors, loadingRequests: false };
        case "SEND_REQUEST_SUCCESS":
            const updatedSuggestions = state.suggestions.filter(req => action.payload.id != req.id)
            return { ...state, suggestions: updatedSuggestions };
        case "ACCEPT_REQUEST_SUCCESS":
            const acceptRequests = state.requests.filter(req => action.payload.id != req.id)
            return { ...state, requests: acceptRequests };
        case "DECLINE_REQUEST_SUCCESS":
            const declineRequests = state.requests.filter(req => action.payload.id != req.id)
            return { ...state, requests: declineRequests };
        case "REMOVE_FRIEND_SUCCESS":
            const friendRemoved = state.connections.filter(cn => action.payload.id != cn.id)
            return { ...state, connections: friendRemoved };
        case "GET_NOTIFICATIONS_SUCCESS":
            const unreadCount = action.payload.unread_notification_count;
            const totalNotif = action.payload.notifications;
            return { ...state, notifications: totalNotif, unreadNotifCount: unreadCount };
        case "MARK_ALL_NOTIFICATIONS_SUCCESS":
            return { ...state, unreadNotifCount: 0 };
        case "ADD_NEW_NOTIFICATIONS":
            const updatedNotifList = [...state.notifications];
            updatedNotifList.unshift(action.payload);
            const updateCount = state.unreadNotifCount+1;
            return { ...state, notifications: updatedNotifList, unreadNotifCount: updateCount };
        default:
            return {...state};
    }
}

export default networkReducer;
