// import "./style.scss";
import Image from "next/image";
import React, { useState } from "react";
import dynamic from "next/dynamic";
const Slider = dynamic(() => import("react-slick"), { loading: () => <></> });
import Link from "next/link";
import BayanModal from "../../../../utils/modal";

function Testimony(props) {
  const { primaryColor, secondaryColor, gradient } = props.theme.bayanTheme;
  const [showVideo, setShowVideo] = useState(false);
  const [videoID, setVideoID] = useState("");
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    infinite: true,
    dots: false,
    draggable: true,
    swipe: true,
    touchThreshold: 100,
    lazyLoad: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleVideoSpreaming = (id) => {
    setVideoID(id);
    setShowVideo(true);
  };

  const closeModal = () => {
    setVideoID("");
    setShowVideo(false);
  };

  return (
    <>
      {showVideo && (
        <BayanModal
          videoID={videoID}
          theme={props.theme}
          closeModal={closeModal}
        />
      )}
      <div className="bayan-faculty blue-bg">
        <h1 className="text-center headingF headingF-sm">
          {props?.testimony?.heading}
        </h1>
        <div className="container">
          <Slider {...settings}>
            {props?.testimony?.testimonies.map((testimony) => {
              return (
                <div
                  className="inner-block"
                  key={testimony.name}
                  onClick={() =>
                    handleVideoSpreaming(testimony?.youtube_embed_url)
                  }
                >
                  <Image
                    src={`${process.env.CMS_API_ORIGIN}${testimony?.thumbnail?.url}`}
                    alt={testimony.name}
                    width={290}
                    height={150}
                    loading="lazy"
                    className="mob-img"
                  />
                  {/* <LazyLoadImage threshold={0} src={testimony.thumbnail} alt={testimony.name} /> */}
                  {/* <img src={testimony.thumbnail} alt={testimony.name} style={{ cursor: 'pointer' }}/> */}
                  <h1>{testimony.name}</h1>
                  <p>{testimony.education}</p>
                  <p>{testimony.class_year}</p>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="mobile-only see-all">
          <Link href="/testimonials" as="/testimonials">
            <a>Watch More Testimonials</a>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Testimony;
