import React, { useEffect } from "react";

const HubspotRequestInfoForm = ({ closeModals }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: process.env.HUBSPOT_PORTAL_ID,
          formId: process.env.HUBSPOT_REQUEST_INFO_FORM_ID,
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <div className="register register-steps register-modal show-password-modal ">
      <div className="wrapper wrapper-md">
        <div className="box gray-bg">
          <div className="request-info-close" onClick={closeModals}>
            &#10005;
          </div>
          <div id="hubspotForm"></div>
        </div>
      </div>
    </div>
  );
};

export default HubspotRequestInfoForm;
