import { toastr } from "react-redux-toastr";
import serviceCalls from "../services/serviceCalls";

export const getLearnWorldUrl = async (redirectURL) => {
	try {
		const result = await serviceCalls.post(`/api/get_sso_url`, { redirectURL: redirectURL}, true);
		if (result?.status == 422 || result?.status == "error" || result?.data?.status == "error") {
			throw new Error(result?.data?.message);
		} else {
			return result;
		}
	} catch (e) {
		toastr.error("Sorry!", "Soemthing went wrong.");
		return {};
	}
};

export const getLWUserProfile = async () => {
	try {
		const result = await serviceCalls.get("/api/get_lw_user_profile", {}, true);
		if (result?.status == 422 || result?.status == 'error' || result?.data?.status == "error") {
			throw new Error(result?.data?.message);
		} else {
			return result;
		}
	} catch(e) {
		// toastr.error("Sorry!", "Something went wrong.");
		return {};
	}
}

export const getLWUserCourses = async () => {
	try {
		const result = await serviceCalls.get("/api/get_lw_user_courses", {}, true);
		if (result?.status == 422 || result?.status == 'error' || result?.data?.status == "error") {
			throw new Error(result?.data?.message);
		} else {
			return result;
		}
	} catch (e) {
		toastr.error("Sorry!", "Something went wrong fetching courses information.");
		return {};
	}
}
