import { 
    getAllThreads,
    getChatMsgs,
    sendChatMsg,
    markAsRead,
    userTyping,
    userStopTyping
} from '../../api/messages';
import {toastr} from "react-redux-toastr";

export const getAllFriendsChat = () => async dispatch => {
    try {
        const response = await getAllThreads();
        if ( response.data && response.data.length) {
            dispatch({
                type: 'GET_ALL_CHAT_THREADS_SECCUSS',
                payload: response.data
            })
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong with chats");
    }
};

export const getChatHistory = (data) => async dispatch => {
    try {
        const response = await getChatMsgs(data);
        if ( response.data && Object.keys(response.data).length) {
            return response.data
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong with history.");
    }
};

export const openChatBox = (data) => async dispatch => {
    try {
        if ( data && Object.keys(data)?.length) {
            dispatch({
                type: 'OPEN_CHAT_BOX',
                payload: data
            })
        }
    } catch (e) {
        toastr.error("Sorry!", "Couldn't open chat.");
    }
};

export const closeChatBox = (data) => async dispatch => {

    try {
        if ( data && data.chatId) {
            dispatch({
                type: 'CLOSE_CHAT_BOX',
                payload: data
            })
        }
    } catch (e) {
        toastr.error("Sorry!", "Couldn't close chat.");
    }
};

export const sendMsg = (data) => async dispatch => {

    try {
        if ( data ) {
            dispatch({
                type: 'SEND_MSG_SUCCESS',
                payload: data
            })
        }
        const response = await sendChatMsg(data.message);
    } catch (e) {
        toastr.error("Sorry!", "Couldn't send chat.");
    }
};

export const markChatAsRead = (data) => async dispatch => {
    try {
        const response = await markAsRead(data);
        if ( data ) {
            dispatch({
                type: 'MARKED_CHAT_AS_READ',
                payload: data
            })
        }
    } catch (e) {
        toastr.error("Sorry!", "Couldn't marked chat as read.");
    }
};

export const addMsgViaSocket = (data) => async dispatch => {
    try {
        if ( data ) {
            dispatch({
                type: 'SEND_MSG_SUCCESS',
                payload: data
            })
        }
    } catch (e) {
        toastr.error("Sorry!", "Couldn't recieve chat.");
    }
};

export const userStartedTyping = (data) => async dispatch => {
    try {
        const response = await userTyping(data);
        if ( response.status == 'success') {
            return response
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong with history.");
    }
};
export const userStoppedTyping = (data) => async dispatch => {
    try {
        const response = await userStopTyping(data);
        if ( response.status == 'success') {
            return response
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong with history.");
    }
};

export const addTypingThread = (data) => async dispatch => {
    try {
        if ( data ) {
            dispatch({
                type: 'ADD_TYPING_THREAD',
                payload: data
            })
        }
    } catch (e) {
        toastr.error("Sorry!", "Couldn't open inbox.");
    }
};
export const removeTypingThread = (data) => async dispatch => {
    try {
        if ( data ) {
            dispatch({
                type: 'REMOVE_TYPING_THREAD',
                payload: data
            })
        }
    } catch (e) {
        toastr.error("Sorry!", "Couldn't open inbox.");
    }
};

export const setRegisterNotification = (data) => async dispatch => {
    try {
        if ( data ) {
            dispatch({
                type: 'SET_REG_NOTIF',
                payload: data
            })
        }
    } catch (e) {
        console.error("error on setting socket:", e);
    }
};
