import React, { useState, useEffect } from "react";
import Link from "next/link";
import Image from "next/image";
// const Slider = dynamic(() => import('react-slick'), { loading: () => <></>});
import Slider from "react-slick";
import { shallowEqual, useSelector } from "react-redux";
// import { Redirect } from "react-router-dom";
// import "./style.scss";

const settings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  // arrows: true,
  infinite: true,
  dots: true,
  draggable: true,
  swipe: true,
  touchThreshold: 100,
  swipeToSlide: true,
  lazyLoad: false,
  autoplay: true,
  autoplaySpeed: 5000,
};

function Banner(props) {
  const { primaryColor, secondaryColor, gradient } = props.theme.bayanTheme;
  const { bannersData } = props;
  const [isMobile, setIsMobile] = useState(false);
  const { userId } = useSelector(
    (state) => ({
      userId: state.userAuth.userId,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (window.innerWidth < 767) {
      setIsMobile(true);
    }
  }, []);

  return (
    <>
      <div className="bayan-banner">
        <Slider {...settings}>
          {/* <div className="byan-bg-img opac2">
            <Image
              src={`${process.env.IMAGES_BASE_URL}/static-content/${
                isMobile ? "mobile_Group 6284@2x (1).png" : "desktop_1.png"
              }`}
              alt="download_App"
              layout="fill"
              loading="lazy"
              className={isMobile ? "mob-img" : "desk-img"}
            />
            <div className="desktop_View">
              <img
                src={`${process.env.IMAGES_BASE_URL}/static-content/Group 6280@2x.png`}
                alt="download_App"
                className="threemobiles"
              />
              <div className="text-holder w3-animate-opacity first-banner-container">
                <div className="text-container text-center-mobile first-banner-text-container">
                  <p className="mb-15">Join us on mobile!</p>
                  <h1>Download Our App</h1>
                  <br></br>
                  <h2 style={{ fontSize: "16px" }}>
                    Keep up to date with the latest Bayan news, connect with
                    friends and with your on-demand classes
                  </h2>
                  <h2 style={{ fontSize: "15px" }}>
                    <b>Available on the App Store and Google Play</b>
                  </h2>
                </div>
                <div className="downloadbuttons">
                  <Link
                    passHref
                    href="https://play.google.com/store/apps/details?id=com.bayanonline"
                    as="https://play.google.com/store/apps/details?id=com.bayanonline"
                  >
                    <a target="_blank" style={{}}>
                      <img
                        src={`${process.env.IMAGES_BASE_URL}/static-content/Mask Group 17.png`}
                      />
                    </a>
                  </Link>
                  <Link
                    passHref
                    href="https://apps.apple.com/us/app/bayan-online/id1531952970"
                    as="https://apps.apple.com/us/app/bayan-online/id1531952970"
                  >
                    <a target="_blank" style={{ marginLeft: "20px" }}>
                      <img
                        src={`${process.env.IMAGES_BASE_URL}/static-content/Mask Group 16.png`}
                      />
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
          {bannersData[0]?.banner?.map((data) => {
            return (
              !data.hidden && (
                <div className="byan-bg-img">
                  {data.type === "Custom" ? (
                    <>
                      <Image
                        src={`${process.env.CMS_API_ORIGIN}${data?.image?.url}`}
                        layout="fill"
                        loading="lazy"
                        className={isMobile ? "mob-img" : "desk-img"}
                      />
                      <div className="layer"></div>
                      <div
                        className="text-holder w3-animate-opacity"
                        style={
                          data?.alignment === "Center" && !isMobile
                            ? { textAlign: "center" }
                            : {}
                        }
                      >
                        <div className="text-container text-center-mobile">
                          {data?.intro ? (
                            <>
                              <p
                                className={
                                  data?.alignment === "Center" ? "" : "mb-15"
                                }
                              >
                                {data?.intro}
                              </p>
                              <h1>{data?.title}</h1>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: data?.caption,
                                }}
                              ></div>
                            </>
                          ) : (
                            <>
                              <h1 className="text_Sizing">{data.title}</h1>
                              <div className="leadrship_slider">
                                <h2 className="leadrship_slider">
                                  {data.caption}
                                </h2>
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          className="button-container"
                          style={isMobile ? {} : {}}
                        >
                          {data?.link?.includes("bayanonline") ? (
                            <Link passHref href={data.link}>
                              <a
                                style={{
                                  backgroundColor: `${
                                    data?.intro ? secondaryColor : "white"
                                  }`,
                                  color: `${data?.intro ? "" : primaryColor}`,
                                }}
                                className={`bayan-button apply-btn ${
                                  data?.alignment === "Center" &&
                                  "bayan-support-btn"
                                }`}
                              >
                                {data.button_text}
                              </a>
                            </Link>
                          ) : (
                            <a
                              target="_blank"
                              href={data.link}
                              style={{
                                backgroundColor: `${
                                  data?.intro ? secondaryColor : "white"
                                }`,
                                color: `${data?.intro ? "" : primaryColor}`,
                              }}
                              className={`bayan-button apply-btn ${
                                data?.alignment === "Center" &&
                                "bayan-support-btn"
                              }`}
                            >
                              {data.button_text}
                            </a>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {data?.link?.includes("bayanonline") ? (
                        <Link passhref href={data?.link} as={data?.link}>
                          <a>
                            <Image
                              src={`${process.env.CMS_API_ORIGIN}${data?.image?.url}`}
                              layout="fill"
                              loading="lazy"
                              className={`${
                                isMobile ? "mob-img" : "desk-img"
                              } ramadan-2021`}
                            />
                          </a>
                        </Link>
                      ) : (
                        <a href={data?.link} target="_blank">
                          <Image
                            src={`${process.env.CMS_API_ORIGIN}${data?.image?.url}`}
                            layout="fill"
                            loading="lazy"
                            className={`${
                              isMobile ? "mob-img" : "desk-img"
                            } ramadan-2021`}
                          />
                        </a>
                      )}
                    </>
                  )}
                </div>
              )
            );
          })}
        </Slider>
      </div>
    </>
  );
}

export default Banner;
