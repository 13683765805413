import { useRef, useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import orderBy from 'lodash/orderBy';
import InfiniteScroll from 'react-infinite-scroller';
import ReactLoading from "react-loading";
import { getChatHistory } from './../../../../store/actions/messages'
import Message from './Messages';

const _ = {
  orderBy: orderBy
}

function MessageList(props) {
  let scrollList = useRef();
  const dispatch = useDispatch();

  const [hasMoreChat, setHasMoreChat] = useState(true);
  const [historyIndex, setHistoryIndex] = useState(1);
  const [chatHistory, setChatHistory] = useState([]);

  const { userTypingThreads } = useSelector((state) => ({
		userTypingThreads: state.messagesReducer.userTypingThreads,
	}), shallowEqual);

  useEffect(() => {
    if (scrollList) {
      scrollList.scrollTop = scrollList.scrollHeight;
    }
  }, [props.messages.length]);

  const loadChatHistory = (start) => {
    setHasMoreChat(false);
    let data ={
      second_user_id: props.friendProfile?.id,
      offset: historyIndex*10,
      length: 10,
    };
    dispatch(getChatHistory(data))
    .then(res => {
      if(res?.messages && res?.messages.length) {
        let currentChat = [...res.messages, ...chatHistory];
        setChatHistory(currentChat);
        setHasMoreChat(true);
        setHistoryIndex(historyIndex+1);
      } else if(!res?.messages.length) {
        setHasMoreChat(false);
      }
    })
    .catch(err => {
      setHasMoreChat(false);
      console.error("Couldn't load data:", err);
    });
  }

  return (
    <div className="sc-message-list" ref={el => scrollList = el}>
      <InfiniteScroll
        pageStart={2}
        initialLoad={false}
        loadMore={loadChatHistory}
        hasMore={hasMoreChat}
        loader={!!chatHistory.length && <div className="sc-message"><div className="file-loader" key={0} style={{margin: "auto"}} ></div></div>}
        useWindow={false}
        isReverse={true}
      >
        {
          chatHistory && !!chatHistory.length && 
          _.orderBy(chatHistory, 'id', ['asc'])
          .map((message, i) => {
            return <Message 
              message={message} 
              imageUrl={props.imageUrl} 
              key={i}
              userAuth={props.userAuth}
            />;
          })
        }
        {
          props.messages?.slice(0)?.reverse()?.map((message, i) => {
            return <Message 
              message={message} 
              imageUrl={props.imageUrl} 
              key={i}
              userAuth={props.userAuth}
            />;
          })
        }
      </InfiniteScroll>
      {
        userTypingThreads?.includes(props.friendProfile?.id) &&
        <div className="sc-message">
          <div className="typing-msg">
            Typing
            <ReactLoading type={'bubbles'} color="#919191" height={'30px'} width={'30px'}/>
          </div>
        </div>
      }
      {
        props.fileLoader &&
        <div className="sc-message">
          <div className="sc-message--content sent">
            <div className="file-loader"></div>
          </div>
        </div>
      }
    </div>
  );
}

export default MessageList;
