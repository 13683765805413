const initialClassesData = {
    classes: [],
    loadingClasses: false
};

const classReducer = (state = initialClassesData, action) => {
    switch (action.type) {
        case "GET_CLASS_START":
            return { ...state, loadingClasses: true};
        case "GET_CLASS_SUCCESS":
            return { ...state, classes: action.payload.courses, loadingClasses: false};
        case "GET_POST_FAILURE":
            return {...state, errors: action.errors};
        default:
            return {...state};
    }
}

export default classReducer;
