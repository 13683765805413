const initialState = {
	userId: null,
	name: null,
	email: null,

	verifyEmail: false,

	gender: '',
	role: '',

	activityProgram: '',
	authenticated: false, // is_verified...
	isSignupCompleted: false,
	isNewUser: false,

	avatar: '',
	source: null,
	topics: [],

	coverPhoto: null,
	bio: '',

	token: null,
	closeEditBox: false,
	appear_in_suggestions: false, 
	connection_requests: false,   
	show_profile_picture: false,
	show_last_name: false,
	is_kajabi_user: false
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case "AUTH_SUCCESS":
			// TODO: Will add this step back once re-add email verification
			//authenticated: action.payload.user.is_verified
			return { ...state, userId: action.payload.user.id, name: action.payload.user.first_name + " " + action.payload.user.last_name, isSignupCompleted: action.payload.user.is_signup_completed, authenticated: true, email: action.payload.user.email, isNewUser: action.payload.user.is_new_user, avatar: action.payload.user.image_url, token: action.payload.token, coverPhoto: action.payload.profile?.cover_url, bio: action.payload.profile?.bio, 
				appear_in_suggestions: action.payload.user?.appear_in_suggestions == 1 ? true : false, 
				connection_requests: action.payload.user?.connection_requests == 1 ? true : false,  
				show_profile_picture: action.payload.user?.show_profile_picture == 1 ? true : false,  
				show_last_name: action.payload.user?.show_last_name == 1 ? true : false, 
				is_kajabi_user: action.payload.user?.is_kajabi_user == 1 ? true : false, 

			};
		case "REGISTER_DONE":
			return { ...state, userId: action.payload.id, name: action.payload.name, email: action.payload.email, token: action.payload.token, isNewUser: action.payload.isNewUser || false, authenticated: action.payload.authenticated || false, coverPhoto: action.payload.profile?.cover_url, bio: action.payload.profile?.bio, avatar: action.payload.avatar || null };
		case "STEP1_DONE":
			return { ...state, verifyEmail: action.payload.verifyEmail, authenticated: true };
		case "STEP2_DONE":
			return { ...state, gender: action.payload.gender };
		case "STEP3_DONE":
			return { ...state, role: action.payload.role };
		case "STEP4_DONE":
			return { ...state, activityProgram: action.payload.activityProgram };
		case "STEP4_ACTIVATE_DONE":
			return { ...state, activityProgram: action.payload.activityProgram, isSignupCompleted: true, isNewUser: true };
		case "STEP5_DONE":
			return { ...state, avatar: action.payload.avatarURL };
		case "STEP6_DONE":
			return { ...state, topics: action.payload.topics, isSignupCompleted: true, isNewUser: true };
		case "WELCOME_TOUR_DONE":
			return { ...state, isNewUser: false };
		case "COVER_PHOTO_SUCCESS":
			return { ...state, coverPhoto: action.payload.cover_url };
		case "EDIT_IMAGE_SUCCESS":
			return { ...state, avatar: action.payload.image_url };
		case "EDIT_BIO_SUCCESS":
			return { ...state, bio: action.payload.bio };
		case "UPDATE_PROFILE_SUCCESS":
			return { ...state, name: action.payload.data.first_name + " " + action.payload.data.last_name, role: action.payload.data?.roles[0]?.name, closeEditBox: !state.closeEditBox};
		default:
			return state;
	}
};

export default authReducer;
