export const welcomeTooltipSteps = [
	{
		target: "body",
		title: "Welcome to Bayan!",
		content: "Founded in 2011, Bayan is a graduate school that educates American Muslim scholars and religious leaders, both men and women. Bayan offers graduate masters and doctorate level degrees in various fields of Islamic religious sciences",
		placement: "center",
		disableBeacon: true,
		floaterProps: { disableAnimation: true }
	},
	{
		target: "body",
		title: "Welcome to Bayan!",
		content: "Founded in 2011, Bayan is a graduate school that educates American Muslim scholars and religious leaders, both men and women. Bayan offers graduate masters and doctorate level degrees in various fields of Islamic religious sciences",
		placement: "center",
		disableBeacon: true,
		floaterProps: { disableAnimation: true }
	},
	{
		target: "body",
		title: "Welcome to Bayan!",
		content: "Founded in 2011, Bayan is a graduate school that educates American Muslim scholars and religious leaders, both men and women. Bayan offers graduate masters and doctorate level degrees in various fields of Islamic religious sciences",
		placement: "center",
		disableBeacon: true,
		floaterProps: { disableAnimation: true }
	},
	{
		target: "body",
		title: "Welcome to Bayan!",
		content: "Founded in 2011, Bayan is a graduate school that educates American Muslim scholars and religious leaders, both men and women. Bayan offers graduate masters and doctorate level degrees in various fields of Islamic religious sciences",
		placement: "center",
		disableBeacon: true,
		floaterProps: { disableAnimation: true }
	},
	{
		target: "body",
		title: "Welcome to Bayan!",
		content: "Founded in 2011, Bayan is a graduate school that educates American Muslim scholars and religious leaders, both men and women. Bayan offers graduate masters and doctorate level degrees in various fields of Islamic religious sciences",
		placement: "center",
		disableBeacon: true,
		floaterProps: { disableAnimation: true }
	}
]


export const chaplain = {
	id: process.env.CHAPLAIN_ID,
	first_name: '',
	last_name: '',
	image_url: '',
	messages: []
}

export const representative = {
	id: process.env.REPRESENTATIVE_ID,
	first_name: '',
	last_name: '',
	image_url: '',
	messages: []
}

export const socialLinks = {
	facebook: 'https://www.facebook.com/bayanchicago',
	twitter: 'https://twitter.com/bayanchicago',
	instagram: 'https://www.instagram.com/bayanchicago/',
	linkedin: 'https://www.linkedin.com/company/bayangraduate',
	youtube: 'https://www.youtube.com/@bayanchicago',
}
