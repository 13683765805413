import React, { useEffect } from "react";
import HubspotRequestInfoForm from "./hubspotRequestInfoForm";
// import RequestInfoForm from "./requestInfoForm";

const RequestInfoModal = (props) => {
  return (
    <>
      <HubspotRequestInfoForm {...props} />
      {/* <RequestInfoForm {...props} /> */}
    </>
  );
};

export default RequestInfoModal;
