import TextMessage from './TextMessage';
import EmojiMessage from './EmojiMessage';
import FileMessage from './FileMessage';

function Message(props) {
  const { userAuth } = props;

  const _renderMessageOfType = (type) => {
    switch(type) {
    case 'text':
      return <TextMessage {...props.message} />;
    case 'emoji':
      return <EmojiMessage {...props.message} />;
    case 'file':
      return <FileMessage {...props.message} />;
    default:
      return <TextMessage {...props.message} />;
    }
  }

  let contentClassList = [
    'sc-message--content',
    (props.message.first_user_id === userAuth.userId ? 'sent' : 'received')
  ];
  return (
    <div className="sc-message">
      <div className={contentClassList.join(' ')}>
        <div className="sc-message--avatar" >
          <img src={props.imageUrl} />
        </div>
        {_renderMessageOfType(props.message.type)}
      </div>
    </div>
  );
}

export default Message;
