import serviceCalls from "../services/serviceCalls";

export const getHeaderComponentData = async (redirectURL) => {
    try {
        const result = await serviceCalls.getFromCMS(`/strapi/headers`, null, false);
        return result;
    } catch (e) {
        return {};
    }
};
