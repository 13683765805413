import FileIcon from './../icons/FileIcon';

const FileMessage = (props) => {

  const checkURL = (url) => {
    return(url.match(/\.(jpeg|jpg|gif|png|svg)$/) != null);
  }

  const isImg = checkURL(props?.message);
  return (
    <>
      {
        !isImg ? (
          <a className="sc-message--file" target="_blank" href={props.message} download={props.message}>
            <FileIcon />
            <p>{props.fileName || 'attachment'}</p>
          </a>
        ) : (
          <a className="img-cnt" target="_blank" href={props.message} download={props.message}>
            <img src={props.message} />
          </a>
        )
      }
    </>
  );
};

export default FileMessage;
