import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";

import ChatWindow from './components/ChatWindow';
import './styles';

function MobileChat(props) {

	const { activeChats } = useSelector((state) => ({
		activeChats: state.messagesReducer.activeChats,
	}), shallowEqual);

	return (
		<>
			<div id="sc-launcher">
				{
					activeChats && activeChats.map((chat, index) => {
						return (
							<ChatWindow
								friendProfile={{
									id: chat.id,
									name: chat.first_name + ' ' + chat.last_name,
									imageUrl: chat?.image_url || `${process.env.IMAGES_BASE_URL}/static-content/empty-profile.png.webp`
								}}
								key={index}
								chatIndex={index}
							/>
						)
					})
				}
			</div>
		</>
	)
}

export default MobileChat;
