import dynamic from 'next/dynamic';
// const Header = dynamic(() => import('../../components/static/Header'), { loading: () => <></>, ssr: true });
import Header from '../../components/static/Header';
// const MobileHeader = dynamic(() => import('../../components/static/mobileHeader'), { loading: () => <></>, ssr: true });
import MobileHeader from '../../components/static/mobileHeader';
const AppHeader = dynamic(() => import('../../components/app/Header'), { loading: () => <></>, ssr: false });
const Footer = dynamic(() => import('../../components/static/Footer'), { loading: () => <></>, ssr: false });
const AppFooter = dynamic(() => import('../../components/app/footer'), { loading: () => <></>, ssr: false });

// import Footer from '../../components/static/Footer';

import React, { useState, useEffect } from 'react';

// starting redux stuff ...
import store from '../../store';
import { createWrapper } from 'next-redux-wrapper';
// ending redux stuff ...

// const HeadContent = dynamic(() => import('../../hoc/headContent'), { loading: () => <></>, ssr: true });
import HeadContent from '../../hoc/headContent';
import {getAboutPageData} from "../../api/about";
import {getHeaderComponentData} from "../../api/header";
// import "./style.scss";

function Layout(props) {
	const { userAuth } = store.getState();
	const [isLogin, setIsLogin] = useState(false);

	useEffect(() => {
		if (userAuth.authenticated && userAuth.token) {
			setIsLogin(true);
		}

	}, []);

	return(
		<>
			<HeadContent
				title={props.meta.title}
				description={props.meta.description}
				route={props.meta.route}
				image_url={props.meta.image_url}
			/>
				<div className="app-layout">
					<MobileHeader theme={props.theme} />
					{
						isLogin ?
						<AppHeader theme={props.theme} /> : <Header theme={props.theme} />
					}
					<div className="app-content">
							{props.children}
					</div>
					{props.showFooter == false ? <></> : <Footer /> }
						{
							isLogin ? <AppFooter/> : <></>
						}

						{/* For FB Chat */}
						<div id="fb-root"></div>
						<div className="fb-customerchat" page-id="246278615482742" theme-color="#274B61"></div>

						{/* Code For Donation */}
						<a href="#XCURMUKD" style={{ display: 'none' }}></a>
				</div>	
		</>
	);
}


const makeStore = () => store;
const wrapper = createWrapper(makeStore);

// export default Layout;
export default wrapper.withRedux(Layout);
