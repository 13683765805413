import Head from "next/head";
// import React, { lazy, Suspense } from 'react';
// const ChatButton = lazy(() => import('../components/libraries/freshWorkChatButton'));
// import dynamic from "next/dynamic";
// const ChatButton = dynamic(() => import("../components/libraries/freshWorkChatButton"), { loading: () => <></>, ssr: true });
// const FundRaiseUp = dynamic(() => import("../components/libraries/fundraise"), { loading: () => <></>, ssr: true });
// import ChatButton from '../components/libraries/freshWorkChatButton';
// import FundRaiseUp from '../components/libraries/fundraise';
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { withUserAgent } from "next-useragent";

function HeadContent({ description, title, route, image_url, ua }) {
  const url = process.env.SITE_NAME;

  const { userId } = useSelector(
    (state) => ({
      userId: state.userAuth.userId,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (ua?.source.toLowerCase().indexOf("lighthouse") === -1) {
      // fundraise script....
      setTimeout(() => {
        (function (w, d, s, n, a) {
          if (!w[n]) {
            var l = "call,catch,on,once,set,then,track".split(","),
              i,
              o = function (n) {
                return "function" == typeof n
                  ? o.l.push([arguments]) && o
                  : function () {
                      return o.l.push([n, arguments]) && o;
                    };
              },
              t = d.getElementsByTagName(s)[0],
              j = d.createElement(s);
            j.async = !0;
            j.src = "https://cdn.fundraiseup.com/widget/" + a;
            t.parentNode.insertBefore(j, t);
            o.s = Date.now();
            o.v = 4;
            o.h = w.location.href;
            o.l = [];
            for (i = 0; i < 7; i++) o[l[i]] = o(l[i]);
            w[n] = o;
          }
        })(window, document, "script", "FundraiseUp", "ATPSCCEC");
        console.log("Loaded fundraiseup script");
      }, 750);

      // Facebook Chat script
      // if (userId == null) {
      // 	let widget = document.getElementById('facebook-jssdk');
      // 	if (widget) {
      // 		console.log("Removed  Loaded customerchat script");
      // 		widget.remove();
      // 	}
      // 	setTimeout(() => {
      // 		window.fbAsyncInit = function () {
      // 			FB.init({
      // 				xfbml: true,
      // 				version: 'v9.0'
      // 			});
      // 		};
      // 		(function (d, s, id) {
      // 			var js, fjs = d.getElementsByTagName(s)[0];
      // 			if (d.getElementById(id)) { return; }
      // 			js = d.createElement(s); js.id = id;
      // 			js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
      // 			fjs.parentNode.insertBefore(js, fjs);
      // 		}(document, 'script', 'facebook-jssdk'));

      // 		console.log("Loaded customerchat script");
      // 	}, 750);
      // }
      // else{
      // 	let widget = document.getElementById('facebook-jssdk');
      // 	if (widget) widget.remove();
      // }

      // Freshchat script
      // if(userId == null) {
      // 	let widget = document.getElementById('fc_frame');
      // 	if (widget) {
      // 		if (widget.style.display === 'none') widget.style.display = 'block';
      // 	}
      // 	else {
      // 		setTimeout(() => {
      // 			async function initFreshChat() {
      // 				window.fcWidget.init({
      // 					token: "d540ca1e-5fd8-4186-8e61-f7f289b175a8",
      // 					host: "https://wchat.freshchat.com"
      // 				});
      // 			}
      // 			function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"freshchat-js-sdk")}window.addEventListener?window.addEventListener("load",initiateCall,!1):window.attachEvent("load",initiateCall,!1);
      // 			console.log("Loaded freshchat script");
      // 		}, 500);
      // 	}
      // }
      // else{
      // 	let widget = document.getElementById('fc_frame');
      // 	if (widget) widget.style.display = 'none';
      // }
    }
  }, []);

  return (
    <Head>
      {/* Meta tags for browser link preview  */}
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={description} />
      <meta
        name="viewport"
        content="width=device-width,minimum-scale=1, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <meta name="type" content="website" />
      <meta name="url" rel="canonical" content={url + route} />
      <meta
        name="image"
        content={
          image_url != undefined && image_url != null
            ? image_url
            : `${process.env.IMAGES_BASE_URL}/static-content/Landing+Page+Banner+.png`
        }
      />
      <link
        href={`${process.env.IMAGES_BASE_URL}/static-content/bayanLogo.png.webp`}
        rel="shortcut icon"
      ></link>
      {process.env.NODE_ENV !== "production" && (
        <link
          rel="stylesheet"
          type="text/css"
          href={"/_next/static/css/styles.chunk.css?v=" + Date.now()}
        />
      )}

      {/* Meta tags for social media link preview  */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url + route} />
      <meta
        property="og:image"
        content={
          image_url != undefined && image_url != null
            ? image_url
            : `${process.env.IMAGES_BASE_URL}/static-content/Landing+Page+Banner+.png`
        }
      />
      <meta property="fb:app_id" content={process.env.Facebook_APP_ID} />
      {/* Meta tags for twitter link preview  */}
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={url + route} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={
          image_url != undefined && image_url != null
            ? image_url
            : `${process.env.IMAGES_BASE_URL}/static-content/Landing+Page+Banner+.png`
        }
      />

      {/* <FundRaiseUp/> */}
      {/* <ChatButton/> */}
    </Head>
  );
}

export default withUserAgent(HeadContent);
