const initialPublicationsData = {
  publications: [],
  loadingPublications: false,
  errors: null,
  drafts: [],
};

const publicationReducer = (state = initialPublicationsData, action) => {
  switch (action.type) {
    case "GET_PUBLICATIONS_SUCCESS":
      state.publications = action.payload.data;
      return { ...state };
    case "GET_DRAFTS_SUCCESS":
      state.drafts = action.payload.data;
      return {
        ...state,
      };
   

    case "GET_PUBLICATIONS_FAILURE":
      return { ...state, errors: action.errors };
    case "POST_PUBLICATIONS_SUCCESS":
      state.publications.concat(action.payload.data);
      return { ...state };
    case "POST_PUBLICATIONS_FAILURE":
      return { ...state, errors: action.errors };
    default:
      return { ...state };
  }
};

export default publicationReducer;
