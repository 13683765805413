import { combineReducers } from 'redux';
import userAuth from './userAuth';
import { reducer as toastrReducer } from 'react-redux-toastr'
import postReducer from "./postReducer";
import classReducer from "./classReducer";
import networkReducer from "./networkReducer";
import publicationReducer from "./publicationReducer";
import hashtagsReducer from "./hashTagsReducer";
import applicationReducer from "./applicationReducer";
import messagesReducer from "./messagesReducer";

const rootReducer = combineReducers({
	postReducer: postReducer,
	classReducer: classReducer,
	networkReducer: networkReducer,
	userAuth: userAuth,
	publicationReducer: publicationReducer,
	toastr: toastrReducer,
	hashtagsReducer: hashtagsReducer,  
	applicationReducer: applicationReducer,
	messagesReducer: messagesReducer
});

export default rootReducer;
