// import "./style.scss";

function BayanModal(props) {
	return (
		<div className="bayan-popup">
				<div className="bayan-popup-container">
				<div className="bayan-popup-container-holder">
						<span className="back-link-holder">
						<span className="popup-back-link" onClick={props.closeModal}></span>
						</span>
						<iframe className="webinar-video-iframe" src={`https://www.youtube.com/embed/${props.videoID}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
					</div>
				</div>
		</div>
	);
}

export default BayanModal;
