import serviceCalls from "../services/serviceCalls";

export const getStudentBodyProfiles = async () => {
  try {
    const result = await serviceCalls.getFromCMS(
      `/strapi/student-body-profiles`,
      null,
      false
    );
    return result;
  } catch (e) {
    return {};
  }
};
