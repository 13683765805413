import { 
    getAllConnections,
    getNetworkSuggestions,
    getNetworkRequests,
    sendFriendRequest,
    acceptFriendRequest,
    declineFriendRequest,
    getUserNotification,
    removeFriend,
    markAllNotifAsRead
} from '../../api/network';
import {toastr} from "react-redux-toastr";

export function getConnectionsStart() {
    return {
        type: 'GET_CONNECTIONS_START'
    }
};
export function getConnectionsSuccess(payload) {
    return {
        type: 'GET_CONNECTIONS_SUCCESS',
        payload,
    }
};
export function getConnectionsFailure(payload) {
    return {
        type: 'GET_CONNECTIONS_FAILURE',
        payload,
    }
};
export const getConnections = () => async dispatch => {
    dispatch(getConnectionsStart());
    try {
        const response = await getAllConnections();
        if ( response.data && response.data.length) {
            dispatch(getConnectionsSuccess(response));
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong with newsfeed");
        dispatch(getConnectionsFailure(e));
    }
};

export function getSuggestionsStart() {
    return {
        type: 'GET_SUGGESTIONS_START'
    }
};
export function getSuggestionsSuccess(payload) {
    return {
        type: 'GET_SUGGESTIONS_SUCCESS',
        payload,
    }
};
export function getSuggestionsFailure(payload) {
    return {
        type: 'GET_SUGGESTIONS_FAILURE',
        payload,
    }
};
export const getSuggestions = () => async dispatch => {
    dispatch(getSuggestionsStart());
    try {
        const response = await getNetworkSuggestions();
        if ( response.data && response.data.length) {
            dispatch(getSuggestionsSuccess(response));
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong with newsfeed");
        dispatch(getSuggestionsFailure(e));
    }
};

export function getRequestsStart() {
    return {
        type: 'GET_REQUESTS_START'
    }
};
export function getRequestsSuccess(payload) {
    return {
        type: 'GET_REQUESTS_SUCCESS',
        payload,
    }
};
export function getRequestsFailure(payload) {
    return {
        type: 'GET_REQUESTS_FAILURE',
        payload,
    }
};
export const getRequests = () => async dispatch => {
    dispatch(getRequestsStart());
    try {
        const response = await getNetworkRequests();
        if ( response.data && response.data.length) {
            dispatch(getRequestsSuccess(response));
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong with newsfeed");
        dispatch(getRequestsFailure(e));
    }
};

export const sendRequest = (data) => async dispatch => {
    try {
        const params = {};
        params.second_user_id = data.id;
        const response = await sendFriendRequest(params);
        if ( response.status == 'success' ) {
            dispatch({
                type: 'SEND_REQUEST_SUCCESS',
                payload: data,
            })
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong");
    }
};

export const acceptRequest = (data) => async dispatch => {
    try {
        const params = {};
        params.second_user_id = data.id;
        const response = await acceptFriendRequest(params);
        if ( response.status == 'success') {
            dispatch({
                type: 'ACCEPT_REQUEST_SUCCESS',
                payload: data,
            })
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong");
    }
};

export const declineRequest = (data) => async dispatch => {
    try {
        const params = {};
        params.second_user_id = data.id;
        const response = await declineFriendRequest(params);
        if ( response.status == 'success' ) {
            dispatch({
                type: 'DECLINE_REQUEST_SUCCESS',
                payload: data,
            })
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong");
    }
};

export const removeNetworkFriend = (data) => async dispatch => {
    try {
        let params = {
            second_user_id: data?.id
        };
        const response = await removeFriend(params);
        if ( response.status == 'success' ) {
            dispatch({
                type: 'REMOVE_FRIEND_SUCCESS',
                payload: data,
            })
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong");
    }
};

export const getUserNotifications = (user) => async dispatch => {
	try {
        let response = await getUserNotification();
        // TODO: Only showing other notificaitons not mine. but need to handle from backend...
        // response.data.notifications = response.data.notifications.filter((n) => n.user?.id !== user?.userId);
        // TODO: Only showing other notificaitons not mine. but need to handle from backend...
		if (response.status == 'success') {
			dispatch({
				type: 'GET_NOTIFICATIONS_SUCCESS',
				payload: response.data
			});
		}
	} catch (e) {
		toastr.error("Sorry!", "couldn't get notifications.");
		return {};
	}
};

export const readAllNotifications = () => async dispatch => {
	try {
        dispatch({
            type: 'MARK_ALL_NOTIFICATIONS_SUCCESS'
        });
        let response = await markAllNotifAsRead();
	} catch (e) {
		console.error("couldn't mark all notifications as read:", e);
		return {};
	}
};

export const addNewNotification = (data) => {
    return {
        type: 'ADD_NEW_NOTIFICATIONS',
        payload: data,
    }
};
