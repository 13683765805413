const initialHashTagsData = {
  hashtags: [],
  loadingTags: true,
  errors: null,
};

const hashtagReducer = (state = initialHashTagsData, action) => {
  switch (action.type) {
    case "GET_HASHTAG_SUCCESS":
      // console.log(action)
      // if (typeof action.payload.data.length > 0) {
      //   state.hashtags = action.payload.data;
      // } else {
      //   state.hashtags = [];
      // }
      // state.loadingTags = false;
      return { ...state, hashtags: action.payload.data, loadingTags: false };
    default:
      return { ...state };
  }
}

export default hashtagReducer;
