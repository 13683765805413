import { toastr } from 'react-redux-toastr';
import moment from 'moment';

export const generateUniqueId = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

export const parseFBResponse = (data) => {
	let res = {};
	if (data && data?.accessToken) {
		res.third_party_id = data.userID;
		res.name = data.name;
		res.email = data.email;
		res.access_token = data.accessToken;
		res.source_id = 1;
		res.profile_img=data.picture?.data?.url
		// res.picture = data.picture?.data?.url
	}
	return res;
	// ((Object.keys(data?.error).length > 0) || data?.status == "unknown") // FB Error if any...
}

export const parseGoogleResponse = (data) => {
	let res = {};
	if (data && data?.accessToken) {
		res.third_party_id = data.googleId;
		res.name = data.profileObj?.name;
		res.email = data.profileObj?.email;
		res.access_token = data.accessToken;
		res.source_id = 2;
		// res.picture = data.picture?.data?.url
	}
	return res;
	// ((Object.keys(data?.error).length > 0) || data?.status == "unknown") // FB Error if any...
}

export const handleUnAuthorized = (result) => {
		toastr.error(result?.data?.message, "Please Login Again.");
		window.location.href = "/";
}


export const appendProperURLInText = (str) => {
	if (str) {
		const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
		const text1 = str.replace(exp, "<a target='_blank' href='$1'>$1</a>");
		const exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
		return text1.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
	} else {
		return '';
	}
};

export const extractUrlFromText = (str) => {
	if (str) {
		const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
		let text1 = str.replace(exp, "$1");
		const exp2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
		text1 = text1.replace(exp2, ' http://$2');
		const match = text1.match(exp)
		return match
	} else {
		return '';
	}
};


export const getHumanizeTime = (e) => {
	return `${moment.duration(new Date(e).getTime() - new Date().getTime()).humanize()} ago`;
};
