// import "./style.scss";
import Image from 'next/image';
import Link from "next/link";
import React from "react";

function subBanner(props) {
	const { primaryColor } = props.theme.bayanTheme;

	return (
			<div style={{ backgroundColor: primaryColor }}>
				<div className="container">
					<div className="bayan-sub-banner">
						{
							props?.sub_banner?.map((val,i) => {
								return 	<div className="byan-sub-banner-holder" key={i}>
									<div className="icon-container">
										<Link href="/degree-programs" as="/degree-programs">
											<a>
												<Image
													src={`${process.env.CMS_API_ORIGIN}${val?.image?.url}`}
													alt="master-degree-prograe"
													layout="fill"
													loading="lazy"
													className="mob-img"
												/>
											</a>
										</Link>
									</div>
									<div className="text-holder">
										<div className="text-container">
											<Link href="/degree-programs" as="/degree-programs">
												<a dangerouslySetInnerHTML={{__html:val?.text ?? ''}}></a>
											</Link>
										</div>
									</div>
								</div>
							})
						}
					</div>
				</div>
			</div>
	);
}

export default subBanner;
