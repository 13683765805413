import * as types from '../actions/actionTypes';
const initialApplicationData = {
    bayanStep: 'applicantInfo',
    selectedProgramId: '',
    selectedProgramLabel: '',
    bayanProgress: 0,
    countries: [],
    states: [],
    savedSteps: {},
    userId: '',
    appId: '',
    loader: false,
    saveLoader: false,
    appSubmitted: false,
    loggedOutUserEmail: ''
};

const applicationReducer = (state=initialApplicationData, action) => {
  switch(action.type) {

    case types.HANDLE_PROGRAM_SELECTION: 
      return {...state, selectedProgramId: action.payload.programId, selectedProgramLabel: action.payload.programLabel}
    
    case types.HANDLE_LOGGED_OUT:
      return {...state, userId: null, loggedOutUserEmail: action.payload}
    
    case types.HANDLE_LOGGED_IN:
      return {...state, userId: action.payload, loggedOutUserEmail: null}

    case types.HANDLE_FORM_STEP:
      return { ...state, bayanStep: action.payload };

    case types.HANDLE_FORM_LOADER:
      return { ...state, loader: action.payload };

    case types.HANDLE_SAVE_FORM_LOADER:
      return { ...state, saveLoader: action.payload };
    
    case types.GET_COUNTRY:
      return { ...state, countries: action.payload };

    case types.SAVE_STEP_SUCCESS_FOR_LOGGED_IN:
      return { ...state, userId: action.payload.user_id, appId: action.payload.app_id };
    
    case types.SAVE_STEP_SUCCESS_FOR_LOGGED_OUT:
      let firstSavedStep = {...state.savedSteps};
      firstSavedStep['id'] = action.payload.id;
      return { ...state, loggedOutUserEmail: action.payload.email, appId: action.payload.app_id, savedSteps: firstSavedStep };

    case types.SAVE_APP_SUCCESS_FOR_LOGGED_IN:
      return { ...state, userId: action.payload.user_id };
    
      case types.SAVE_APP_SUCCESS_FOR_LOGGED_OUT:
        return { ...state, loggedOutUserEmail: action.payload.user_email };


    case types.STORE_SAVED_DATA:
    	let savedStep = {...state.savedSteps}
      let progress = action.payload.data.percent_completed
    	savedStep[action.payload.step] = action.payload.data
    	savedStep['percent_completed'] = action.payload.data.percent_completed
      return { ...state, savedSteps: savedStep, bayanProgress:  progress};

    case types.SET_SAVED_DATA:
      let payload = action.payload
      const app_id = action.payload.appId || null;
      return { ...state, 
        savedSteps: payload, 
        bayanStep: payload.current_step || 'applicantInfo', 
        bayanProgress: payload.percent_completed || 0, 
        appId: app_id
      };

    case types.APP_SUBMITTED:
      return { ...state, appSubmitted: true};
      
    default:
      return state;
  }
}

export default applicationReducer;
