import dynamic from 'next/dynamic';
import React, { useState, useEffect, useRef } from 'react';
import Link from 'next/link';
// import MenuIcon from '../../common/Menu';
const MenuIcon = dynamic(() => import('../../common/Menu'), { loading: () => <></>, ssr: false });
import MobileChat from './../../common/desktopChatBox/mobileChatBox';
import RequestInfoModal from '../../common/requestInfoModal';

// import "./style.scss";
function MobileHeader(props) {
	const [isMobile, setIsMobile] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);
	const [openRequestInfoModal, setOpenRequestInfoModal] = useState(false);

	useEffect(() => { 
		if (window.innerWidth < 767) {
			setIsMobile(true);
		}
	});

	const handleRequestInfo = () => {
		setOpenRequestInfoModal(true);
	}
	
	const closeModals = () => {
		  setOpenRequestInfoModal(false);
	}

	return (
		<div className="overflow-hidden on-mobile">
			{openRequestInfoModal &&
				<RequestInfoModal 
					closeModals={closeModals}
				/>
			}
			<div className="mobile-header">
				<a onClick={() => setOpenMenu(true)}><img src={`${process.env.IMAGES_BASE_URL}/static-content/mb-menu.png.webp`} width="20" /></a>
				<div className='logo'>	<Link  href="/" as="/"><a ><img src={`/icons/bayan-only.svg`} width="120" /></a></Link></div>
				{/* <span className="logo-mob"><Link href="/" as="/"><span><img src="/icons/logo-in.png"></img> BAYAN</span></Link></span> */}
				<a ></a>
				{/* <a ><img src={`${process.env.IMAGES_BASE_URL}/static-content/mb-search.png.webp`} width="20" /></a> */}
			</div>
			<MenuIcon 
				openMenu={openMenu}
				setOpenMenu={setOpenMenu}
				RequestInfo={handleRequestInfo}
			/>
			{
				isMobile &&
				<MobileChat />
			}
		</div>
	);
}

export default MobileHeader;





