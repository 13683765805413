import serviceCalls from "../services/serviceCalls";
import { toastr } from 'react-redux-toastr';

export const getAllConnections = async () => {
    try {
        const result = await serviceCalls.get('/api/list_user_friends', {}, true);
        if (result?.data?.status == "error") {
            throw new Error(result?.data?.message);
        } else {
            return result;
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong with connections");
        return {};
    }
}
export const getNetworkSuggestions = async () => {
    try {
        const result = await serviceCalls.get('/api/friend_suggestions', {}, true);
        if (result?.data?.status == "error") {
            throw new Error(result?.data?.message);
        } else {
            return result;
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong with connections");
        return {};
    }
}
export const getNetworkRequests = async () => {
    try {
        const result = await serviceCalls.get('/api/list_user_received_friend_requests', {}, true);
        if (result?.data?.status == "error") {
            throw new Error(result?.data?.message);
        } else {
            return result;
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong with connections");
        return {};
    }
}
export const sendFriendRequest = async (data) => {
    try {
        const result = await serviceCalls.post('/api/send_friend_request', data, true);
        if (result?.data?.status == "error") {
            throw new Error(result?.data?.message);
        } else {
            toastr.success("Success","Request has been sent!");
            return result;
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong");
        return {};
    }
}
export const acceptFriendRequest = async (data) => {
    try {
        const result = await serviceCalls.post('/api/accept_friend_request', data, true);
        if (result?.data?.status == "error") {
            throw new Error(result?.data?.message);
        } else {
            toastr.success("Success","Request accepted!");
            return result;
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong");
        return {};
    }
}
export const declineFriendRequest = async (data) => {
    try {
        const result = await serviceCalls.post('/api/remove_friend_request', data, true);
        if (result?.data?.status == "error") {
            throw new Error(result?.data?.message);
        } else {
            toastr.success("Success","Request declined!");
            return result;
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong");
        return {};
    }
}
export const removeFriend = async (data) => {
    try {
        const result = await serviceCalls.post('/api/remove_friend', data, true);
        if (result?.status == "error") {
            throw new Error(result?.message);
        } else {
            toastr.success("Success","Friend removed!");
            return result;
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong");
        return {};
    }
}
export const getUserNotification = async () => {
	try {
		let result = await serviceCalls.get('/api/get_all_notifications', {}, true);
		if (result?.status == 422 || result?.status == "error" || result?.data?.status == "error") {
			throw new Error(result?.data?.message[0]);
		} else {
			return result;
		}
	} catch (e) {
		return {};
	}
}
export const markAllNotifAsRead = async () => {
	try {
		let result = await serviceCalls.post('/api/mark_all_notifications_as_read', {}, true);
		if (result?.status == 422 || result?.status == "error" || result?.data?.status == "error") {
			throw new Error(result?.data?.message[0]);
		} else {
			return result;
		}
	} catch (e) {
        console.error("failed to mark notifications as read:", e);
		return {};
	}
}
