import BayanNews from "../../../common/bayanNews";
import React from "react";

function Media(props) {
	const data = props?.newsEventPageData && props?.newsEventPageData.length ? props?.newsEventPageData[0] : null;
	return (
		<BayanNews page_title={data?.page_title ?? ''} news_events={data?.news_event_media ?? ''}/>
	);
}

export default Media;
