// import "./style.scss";
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import { withUserAgent } from 'next-useragent';
const Slider = dynamic(() => import('react-slick'), { loading: () => <></> });
import Link from 'next/link';
import Image from 'next/image';
import { getLearnWorldUrl } from "../../../../api/learn_worlds";
import { getLWClasses } from "../../../../store/actions/class";
import { generateUniqueId } from './../../../../utils';

const settings = {
	slidesToShow: 3,
	slidesToScroll: 1,
	arrows: true,
	infinite: true,
	dots: false,
	draggable: true,
	swipe: true,
	lazyLoad: true,
	touchThreshold: 100,
	swipeToSlide: true,
	// autoplay: true,
	// autoplaySpeed: 3000,
	responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
}

function Classes(props) {
	const router = useRouter();
	const dispatch = useDispatch();
	const [isLogin, setIsLogin] = useState(false);
	const { primaryColor, secondaryColor, gradient } = props.theme.bayanTheme;
	const [LMSURL, setLMSURL] = useState(null);

	const { user, classes } = useSelector((state) => ({
		user: state.userAuth,
		classes: state.classReducer.classes,
	}), shallowEqual);

	useEffect(() => {
		if (user.authenticated && user.token) {
			setIsLogin(true);
		}
		dispatch(getLWClasses()); 
	}, []);

	useEffect(() => {
		if (LMSURL) {
			window.open(LMSURL);
		}
	}, [LMSURL]);

	const handleCourseButton = (event, data) => {
		event.target.firstElementChild.classList.remove('hidden');
		const redirectURL = `https://direct.bayanclaremont.org/course/${data}`;

		if(props.ua.source !== 'BayanApp') var importantStuff = window.open('https://direct.bayanclaremont.org', '_blank');
		getLearnWorldUrl(redirectURL).then((res) => {
			// setLearnWorlds(res);
			if (res.success) {
				if(props.ua.source !== 'BayanApp') importantStuff.location.href = res.url;
				else window.location.href = res.url;
				// setLMSURL(res.url);
				event.target.firstElementChild.classList.add('hidden');
				// window.localStorage.setItem("selected-course", data);
				// openIframe(res.url);
			}
		});
	}

	const openIframe = (data) => {
		if (data) {
			window.localStorage.setItem("lms_url", data);
			router.push({
				pathname: '/course-library'
			});
		}
	};

	return (
			<div className="bayan-classes bayan-online gray-bg">
				<div className="container">
				<h1 className="text-center headingF" alt="Bayan On-Demand">{props?.bayan_on_demand?.heading || ""}</h1>
				<p className="class-text" dangerouslySetInnerHTML={{__html:props?.bayan_on_demand?.description ?? ''}}></p>
						<div className="see-all">
					<p className="class-text text-center">{props?.bayan_on_demand?.text || ""}</p>
					<a href="https://direct.bayanclaremont.org/" target="_blank">SUBSCRIBE</a>
				</div>
			
				<div className="our-class">
					<Slider {...settings}>
						{
							classes && Object.keys(classes).length ? Object.keys(classes).map((cls) => {
								return <div className="inner-block" key={generateUniqueId()}>
									<Image
										src={`${classes[cls].courseImage.url}`}
										alt={classes[cls].title}
										width="290"
										height="163"
										loading="lazy"
										className="mob-img"
									/>
									<div className="detail">
										<h2>{classes[cls].title}</h2>
										<p className='class-description'>{classes[cls].keywords}</p>
										<a href="https://direct.bayanclaremont.org/" target="_blank" className={`btn-in ${classes[cls].status == "free" ? 'btn-green' : 'btn-yellow'}`}>
											{classes[cls].status == "free" ? "Enroll for Free" :"Subscribe"}
										</a>
									</div>
								</div>
							}) : <></>
						}
					</Slider>
					{/* <Slider {...settings}>
						{
							props.classes?.courses?.length ? props.classes.courses.map((clas) => {
								return <div className="inner-block" key={clas.title}>
									<Image
										src={`${clas.thumbnail}.webp`}
										alt={clas.title}
										width="290"
										height="163"
										loading="lazy"
										className="mob-img"
									/>
									<div className="detail">
										<h2>{clas.title}</h2>
										<p className='class-description'>{clas.description}</p>
										{
											isLogin ? 
												<a className={`btn-in ${clas.course_category == "free" ? 'btn-green' : 'btn-yellow'}`} onClick={(e) => { handleCourseButton(e) }}>
													View Course
													<img className="hidden" src={`/icons/loading.svg`} style={{ height: '25px', marginLeft: '10px' }} />
												</a>
												:
												<Link href={{
													pathname: '/register',
													query: { isSubscribe: true },
												}} as='/register'>
													<a className={`btn-in ${clas.course_category == "free" ? 'btn-green' : 'btn-yellow'}`}>Subscribe</a>
												</Link>
										}

									</div>
								</div>
							}): <></>
						}
					</Slider> */}
					</div>
				</div>

			<div className="see-all">
					<Link href="/library" as="/library">
						<a>See all courses</a>
					</Link>
				</div>
			</div>
	);
}

export default withUserAgent(Classes);
