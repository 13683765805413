import S3 from 'react-aws-s3';

// s3 configuration for react-aws-s3 plugin...
const configForUserProfile = {
	bucketName: process.env.BUCKET,
	dirName: 'user-profile-media', // moving here all user related data...
	region: process.env.REGION,
	accessKeyId: process.env.ACCESS_KEY,
	secretAccessKey: process.env.SECRET_KEY,
	s3Url: process.env.S3_URL, /* optional */
}

export const S3ClientForUserProfile = new S3(configForUserProfile);

const configForUserPost = {
	bucketName: process.env.BUCKET,
	dirName: 'user-post-media', // moving here all user profile related data...
	region: process.env.REGION,
	accessKeyId: process.env.ACCESS_KEY,
	secretAccessKey: process.env.SECRET_KEY,
	s3Url: process.env.S3_URL, /* optional */
}

export const S3ClientForUserPosts = new S3(configForUserPost);


const configForUserApplicationForm = {
	bucketName: process.env.BUCKET,
	dirName: 'user-application-form-media', // moving here all user profile related data...
	region: process.env.REGION,
	accessKeyId: process.env.ACCESS_KEY,
	secretAccessKey: process.env.SECRET_KEY,
	s3Url: process.env.S3_URL, /* optional */
}

export const S3ClientForUserApplications = new S3(configForUserApplicationForm);


const configForChat = {
	bucketName: process.env.BUCKET,
	dirName: 'user-chat', // moving here all user profile related data...
	region: process.env.REGION,
	accessKeyId: process.env.ACCESS_KEY,
	secretAccessKey: process.env.SECRET_KEY,
	s3Url: process.env.S3_URL, /* optional */
}

export const S3ClientForUserChat = new S3(configForChat);

const configForArticle = {
	bucketName: process.env.BUCKET,
	dirName: 'user-articles', // moving here all user profile related data...
	region: process.env.REGION,
	accessKeyId: process.env.ACCESS_KEY,
	secretAccessKey: process.env.SECRET_KEY,
	s3Url: process.env.S3_URL, /* optional */
}

export const S3ClientForUserArticles = new S3(configForArticle);


