import serviceCalls from "../services/serviceCalls";
import { toastr } from 'react-redux-toastr';

export const getAllThreads = async () => {
    try {
        const result = await serviceCalls.get('/api/get_friends_with_chat', {}, true);
        if (result?.status == "error") {
            throw new Error(result?.message);
        } else {
            return result;
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong while fetching friends chats");
        return {};
    }
}

export const getChatMsgs = async (data) => {
    try {
        const result = await serviceCalls.post('/api/get_chat_messages', data, true);
        if (result?.status == "error") {
            throw new Error(result?.message);
        } else {
            return result;
        }
    } catch (e) {
        toastr.error("Sorry!", "Something went wrong while fetching chat history.");
        return {};
    }
}

export const sendChatMsg = async (data) => {
    try {
        const result = await serviceCalls.post('/api/send_message', data, true);
        if (result?.status == "error") {
            throw new Error(result?.message);
        } else {
            return result;
        }
    } catch (e) {
        toastr.error("Sorry!", "Couldn't deliver message.");
        return {};
    }
}

export const markAsRead = async (data) => {
    try {
        const result = await serviceCalls.post('/api/mark_messages_as_read', data, true);
        if (result?.status == "error") {
            throw new Error(result?.message);
        } else {
            return result;
        }
    } catch (e) {
        toastr.error("Sorry!", "Couldn't deliver message.");
        return {};
    }
}

export const userTyping = async (data) => {
    try {
        const result = await serviceCalls.post('/api/user_started_typing', data, true);
        if (result?.status == "error") {
            throw new Error(result?.message);
        } else {
            return result;
        }
    } catch (e) {
        // toastr.error("Sorry!", "something went wrong.");
        return {};
    }
}

export const userStopTyping = async (data) => {
    try {
        const result = await serviceCalls.post('/api/user_stopped_typing', data, true);
        if (result?.status == "error") {
            throw new Error(result?.message);
        } else {
            return result;
        }
    } catch (e) {
        // toastr.error("Sorry!", "something went wrong.");
        return {};
    }
}
